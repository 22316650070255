<template>
  <base-page-layout :title="$route.meta.title">
    <v-card class="mt-3" flat>
      <v-card-title class="mb-6">
        <v-text-field
          v-model="search"
          class="pt-0 mt-0"
          width="300"
          hide-details
          single-line=""
          placeholder="Busca (nome, franquia, ERP, CNPJ)"
          append-icon="search"
          outlined
        />
        <v-spacer />
        <v-btn color="primary" class="mr-4" @click="create">Cadastrar varejista</v-btn>
      </v-card-title>
      <v-card-text>
        <retailers-index-table :search="search" />
      </v-card-text>
    </v-card>
  </base-page-layout>
</template>

<script>
export default {
  name: 'Retailers',
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    RetailersIndexTable: () => import('@/modules/retailers/views/components/tables/RetailersIndexTable.vue')
  },
  data: () => ({
    search: ''
  }),
  methods: {
    create() {
      this.$router.push({ name: 'create-retailer' })
    }
  }
}
</script>
